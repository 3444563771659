import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"

const query = graphql`
  {
    strapiTeaser {
      headline
      headline_prefix
      headline_text
      locale
      teasers {
        icon {
          alternativeText
          localFile {
            url
          }
        }
        title
        text
        link_text
        external_url
      }
    }
  }
`

const Teasers = () => {
  const data = useStaticQuery(query)

  const {
    strapiTeaser: { headline, teasers, headline_prefix, headline_text},
  } = data

  return (
    <section className="teaser-area">
      <div className="container">
        <div className="section-title">
          <h6 className={'headline-prefix'}>{headline_prefix}</h6>
          <h1>{headline}</h1>
          <p >{headline_text}</p>
        </div>

        <div className="row">
          {teasers.map((teaser, idx) => {
            const linkText = teaser.link_text
              ? teaser.link_text
              : null
            const link = teaser.external_url
              ? teaser.external_url
              : null
            return (
              <div className="col-lg-4 col-sm-6" key={idx}>
                <div className="single-teaser-box">
                  <div className="icon">
                    <img src={teaser.icon.localFile.url} alt={teaser.icon.alternativeText}/>
                  </div>
                  <h3>{teaser.title}</h3>
                  <div id="process" >
                    <ReactMarkdown children={teaser.text} />
                  </div>
                  {/*  {link && (
                    <Link to={link} className="view-details-btn">
                      {linkText}
                    </Link>
                  )} */}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}



export default Teasers
