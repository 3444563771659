import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby'
import ReactMarkdown from "react-markdown"


const query = graphql`
    {
      strapiExperience {
        headline
        subtitle
        text
        link_text
        locale
        url
        image {
          alternativeText
          localFile {
                url
          }
        }
        experience {
          icon {
            alternativeText
            localFile {
            url
            }
          }
          subtitle
          title
         }
      } 
    }
`


const AboutUs = () => {
    const data = useStaticQuery(query)

    const {
        strapiExperience: {headline, subtitle, text, image, experience, link_text, url}
    } = data


    return (
        <React.Fragment>


            <div className="about-area pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-image-box">
                                <img src={image.localFile.url} alt={image.alternativeText} />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">

                                    <h2>{headline}</h2>
                                    <h3>{subtitle}</h3>
                                    <ReactMarkdown children={text} />


                                    <ul className="features-list">
                                        {experience.map((exp, idx) => {
                                            return(
                                                    <li key={idx}>
                                                        <img src={exp.icon.localFile.url} alt={exp.icon.alternativeText} />
                                                        <h3>{exp.title}</h3>
                                                        <p>{exp.subtitle}</p>
                                                    </li>
                                            )
                                        })}


                                    </ul>
                                    {/* <Link to={`${url}`} className="default-btn" >
                                        <i className="flaticon-right"></i>
                                        {link_text} <span></span>
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default AboutUs;
