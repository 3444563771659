import React from "react"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import Teasers from "../components/Index/Teasers"
import Founders from "../components/Index/Founders"
import Symptoms from "../components/Index/Symptoms"
import AboutUs from "../components/Index/AboutUs"
import HowItWork from "../components/Index/HowItWork"
import Navbar from "../components/App/Navbar";
import Seo from "../components/App/Seo";
import {graphql} from "gatsby";
import ContactForm from "../components/Contact/ContactForm";
import MailchimpForm from "../components/App/MailchimpForm";

const Home = ({ data })  => {

    const {
        strapiSettings: {seo},
        strapiSeolocalization: {home_locale}
    } = data


    return (
        <Layout >
            <Seo seotags={seo} loc={home_locale} />
            <Navbar />
            <Banner />
            <Teasers />
            <AboutUs />
            <HowItWork />
            {/* <Symptoms /> */}
            <Founders />
            <ContactForm />
            <Footer />
        </Layout>
    )
}
export const query = graphql`
{
strapiSettings {
    seo {
      metatitle
      metadescription
      chanoncial_link
    }
  }
  strapiSeolocalization {
    home_locale {
      default_localization {
        default_url
      }
      localization {
         url
         iso_code {
          code
        }
      }
    }
  }
}
`

export default Home
