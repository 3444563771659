import React from 'react';
import {graphql, useStaticQuery} from "gatsby";

const query = graphql`
    {
      strapiProcess {
        process_steps {
          title
          number
          description
        }
        title
        subtitle
        locale
        image {
          alternativeText
          localFile {
               url
          }
        }
      }
    }
`

const HowItWork = () => {

    const data = useStaticQuery(query)
    const {strapiProcess: {title, subtitle, process_steps, image} } = data

    return (
        <section className="how-its-work-area ptb-100"  >
            <div className="container" >
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">

                            <h2 id="process">{title}</h2>
                            <p>{subtitle}</p>
                            <div className="inner-box">
                                {process_steps.map((processStep, idx)=>{
                                    return(
                                            <div key={idx} className="single-item" >
                                                <div className="count-box">
                                                    {processStep.number}
                                                </div>
                                                <h3>{processStep.title}</h3>
                                                <p>{processStep.description}</p>
                                            </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={image.localFile.url} alt={image.alternativeText} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWork;
