import React from "react"
import { Link } from "gatsby"
import Loadable from "@loadable/component"
import { graphql, useStaticQuery } from "gatsby"

const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: true,
  nav: true,
  dots: false,
  items: 1,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
}

const query = graphql`
  {
    strapiBanner {
    locale
      headerslide {
        external_url
        headline
        link_text
        subtitle
        text
        background_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const Banner = () => {
  const data = useStaticQuery(query)
  const {
    strapiBanner: { headerslide: headerslide },
  } = data
  // Popup Video



  // Owl Carousel
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <React.Fragment>
      {display ? (
        <OwlCarousel
          className="home-slides owl-carousel owl-theme"
          {...options}
        >
          {(headerslide || []).map((hs, idx) => {
            const linkText = hs.link_text
            const link = hs.external_url
              ? hs.external_url
              : null

            const bgImageUrl =  hs.background_image.localFile.childImageSharp.fluid.src;
            const slideStyle = {
              background: `linear-gradient(0deg, rgba(153, 127, 230, 0.3), rgba(153, 127, 230, 0.5)), url(${bgImageUrl})`,
              backgroundSize: 'cover',

          }
            return (
              <div className="main-banner-item" key={idx} style={slideStyle}>
                <div className="d-table">
                  <div className="d-table-cell">
                    <div className="container">
                      <div className="banner-item-content">
                        <p className={'slider-heading'}>{hs.headline}</p>
                        <span className="sub-title">{hs.subtitle}</span>
                        <p>{hs.text}</p>
                        <div className="btn-box">
                          {link && linkText && (
                            <a href={link} className="default-btn" >
                              <i className="flaticon-right"></i>
                              {linkText} <span></span>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

        </OwlCarousel>
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

export default Banner
