import React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'

const query = graphql`
{
  strapiFounders {
    heading
    subtitle
    locale
    founders {
      name
      description
      image {
        alternativeText
        localFile {
          url
        }
      }
      social_media {
        icon {
          alternativeText
          localFile {
            url
          }
        }
        adress
      }
    }
  }
}
`


const Founders = () => {
    const data = useStaticQuery(query)

    const {strapiFounders: {heading, subtitle, founders}} = data


    return (
        <section className="founder-area pt-100 pb-100">
            <div className="container">
                <div className="section-title">
                    <h2>{heading} </h2>
                    <p>{subtitle}</p>
                </div>

                <div className="row">

                    {founders.map((founder, idx)=> {
                        return(
                            <div key={idx} className="col-lg-4 col-sm-6">
                                <div className="single-founder-item-box">
                                    <div className="image">
                                        <img src={founder.image.localFile.url} alt={founder.image.alternativeText} />
                                    </div>
                                    <ul className="social">
                                        {founder.social_media.map((social, idx)=> {

                                            return(
                                                <li key={idx}>
                                                    <Link to={`${social.adress}`} target='_blank' className="d-block">
                                                        <img src={social.icon.localFile.url} alt={social.icon.alternativeText} />
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <div className="content">
                                        <h3>{founder.name}</h3>
                                        <span>{founder.description}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Founders;
